/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(26 163 222 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(26 163 222 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
.container {
  width: 100%;
}
@media (min-width: 360px) {
  .container {
    max-width: 360px;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1535px) {
  .container {
    max-width: 1535px;
  }
}
@media (min-width: 2800px) {
  .container {
    max-width: 2800px;
  }
}
.pointer-events-none {
  pointer-events: none;
}
.\!visible {
  visibility: visible !important;
}
.visible {
  visibility: visible;
}
.collapse {
  visibility: collapse;
}
.fixed {
  position: fixed;
}
.\!absolute {
  position: absolute !important;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.inset-0 {
  inset: 0px;
}
.-bottom-0 {
  bottom: -0px;
}
.-right-0 {
  right: -0px;
}
.-right-2 {
  right: -0.5rem;
}
.-right-5 {
  right: -1.25rem;
}
.-right-9 {
  right: -2.25rem;
}
.-top-0\.5 {
  top: -0.125rem;
}
.-top-7 {
  top: -1.75rem;
}
.-top-\[32px\] {
  top: -32px;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-12 {
  bottom: 3rem;
}
.bottom-5 {
  bottom: 1.25rem;
}
.bottom-7 {
  bottom: 1.75rem;
}
.left-0 {
  left: 0px;
}
.left-1\/2 {
  left: 50%;
}
.left-10 {
  left: 2.5rem;
}
.left-4 {
  left: 1rem;
}
.right-0 {
  right: 0px;
}
.right-1\.5 {
  right: 0.375rem;
}
.right-10 {
  right: 2.5rem;
}
.right-2 {
  right: 0.5rem;
}
.right-2\.5 {
  right: 0.625rem;
}
.right-4 {
  right: 1rem;
}
.right-5 {
  right: 1.25rem;
}
.top-0 {
  top: 0px;
}
.top-1\.5 {
  top: 0.375rem;
}
.top-2 {
  top: 0.5rem;
}
.top-2\.5 {
  top: 0.625rem;
}
.top-4 {
  top: 1rem;
}
.top-5 {
  top: 1.25rem;
}
.top-\[30px\] {
  top: 30px;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-50 {
  z-index: 50;
}
.z-\[1\] {
  z-index: 1;
}
.z-\[2\] {
  z-index: 2;
}
.z-max {
  z-index: 2147483647;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-full {
  grid-column: 1 / -1;
}
.col-start-1 {
  grid-column-start: 1;
}
.col-start-2 {
  grid-column-start: 2;
}
.col-start-3 {
  grid-column-start: 3;
}
.row-start-3 {
  grid-row-start: 3;
}
.m-0 {
  margin: 0px;
}
.m-auto {
  margin: auto;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.-mt-12 {
  margin-top: -3rem;
}
.-mt-2 {
  margin-top: -0.5rem;
}
.-mt-20 {
  margin-top: -5rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-1\.5 {
  margin-bottom: 0.375rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-2\.5 {
  margin-bottom: 0.625rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mb-28 {
  margin-bottom: 7rem;
}
.mb-3\.5 {
  margin-bottom: 0.875rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-\[114px\] {
  margin-bottom: 114px;
}
.mb-\[50px\] {
  margin-bottom: 50px;
}
.ml-0 {
  margin-left: 0px;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-1\.5 {
  margin-left: 0.375rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-7 {
  margin-left: 1.75rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-2\.5 {
  margin-right: 0.625rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-auto {
  margin-right: auto;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-14 {
  margin-top: 3.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-2\.5 {
  margin-top: 0.625rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-\[-10px\] {
  margin-top: -10px;
}
.mt-\[104px\] {
  margin-top: 104px;
}
.mt-\[2\.5px\] {
  margin-top: 2.5px;
}
.mt-\[22px\] {
  margin-top: 22px;
}
.mt-\[26px\] {
  margin-top: 26px;
}
.mt-\[30px\] {
  margin-top: 30px;
}
.mt-\[33px\] {
  margin-top: 33px;
}
.mt-auto {
  margin-top: auto;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.aspect-\[14\/9\] {
  aspect-ratio: 14/9;
}
.aspect-\[2\/1\] {
  aspect-ratio: 2/1;
}
.aspect-\[540\/300\] {
  aspect-ratio: 540/300;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.aspect-video {
  aspect-ratio: 16 / 9;
}
.\!h-auto {
  height: auto !important;
}
.h-1 {
  height: 0.25rem;
}
.h-1\/2 {
  height: 50%;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-75 {
  height: 18.75rem;
}
.h-8 {
  height: 2rem;
}
.h-\[108px\] {
  height: 108px;
}
.h-\[120px\] {
  height: 120px;
}
.h-\[128px\] {
  height: 128px;
}
.h-\[150px\] {
  height: 150px;
}
.h-\[156px\] {
  height: 156px;
}
.h-\[18px\] {
  height: 18px;
}
.h-\[19px\] {
  height: 19px;
}
.h-\[200px\] {
  height: 200px;
}
.h-\[242px\] {
  height: 242px;
}
.h-\[260px\] {
  height: 260px;
}
.h-\[288px\] {
  height: 288px;
}
.h-\[300px\] {
  height: 300px;
}
.h-\[32px\] {
  height: 32px;
}
.h-\[350px\] {
  height: 350px;
}
.h-\[358px\] {
  height: 358px;
}
.h-\[366px\] {
  height: 366px;
}
.h-\[380px\] {
  height: 380px;
}
.h-\[400px\] {
  height: 400px;
}
.h-\[40px\] {
  height: 40px;
}
.h-\[42px\] {
  height: 42px;
}
.h-\[44px\] {
  height: 44px;
}
.h-\[50\%\] {
  height: 50%;
}
.h-\[500px\] {
  height: 500px;
}
.h-\[54px\] {
  height: 54px;
}
.h-\[57px\] {
  height: 57px;
}
.h-\[60px\] {
  height: 60px;
}
.h-\[72px\] {
  height: 72px;
}
.h-\[78px\] {
  height: 78px;
}
.h-\[80px\] {
  height: 80px;
}
.h-auto {
  height: auto;
}
.h-dvh {
  height: 100dvh;
}
.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}
.h-full {
  height: 100%;
}
.h-min {
  height: -moz-min-content;
  height: min-content;
}
.h-screen {
  height: 100vh;
}
.max-h-\[160px\] {
  max-height: 160px;
}
.max-h-\[200px\] {
  max-height: 200px;
}
.max-h-\[264px\] {
  max-height: 264px;
}
.max-h-\[400px\] {
  max-height: 400px;
}
.max-h-\[44px\] {
  max-height: 44px;
}
.max-h-\[500px\] {
  max-height: 500px;
}
.max-h-\[700px\] {
  max-height: 700px;
}
.max-h-\[70px\] {
  max-height: 70px;
}
.max-h-\[72px\] {
  max-height: 72px;
}
.max-h-\[76px\] {
  max-height: 76px;
}
.max-h-\[calc\(100\%-20px\)\] {
  max-height: calc(100% - 20px);
}
.max-h-full {
  max-height: 100%;
}
.max-h-none {
  max-height: none;
}
.max-h-screen {
  max-height: 100vh;
}
.min-h-\[100px\] {
  min-height: 100px;
}
.min-h-\[157px\] {
  min-height: 157px;
}
.min-h-\[200px\] {
  min-height: 200px;
}
.min-h-\[338px\] {
  min-height: 338px;
}
.min-h-\[34px\] {
  min-height: 34px;
}
.min-h-\[400px\] {
  min-height: 400px;
}
.min-h-\[440px\] {
  min-height: 440px;
}
.min-h-\[500px\] {
  min-height: 500px;
}
.min-h-\[54px\] {
  min-height: 54px;
}
.min-h-\[76px\] {
  min-height: 76px;
}
.min-h-\[91px\] {
  min-height: 91px;
}
.min-h-full {
  min-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.\!w-full {
  width: 100% !important;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-10 {
  width: 2.5rem;
}
.w-12 {
  width: 3rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-32 {
  width: 8rem;
}
.w-4 {
  width: 1rem;
}
.w-48 {
  width: 12rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-60 {
  width: 15rem;
}
.w-64 {
  width: 16rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-\[100px\] {
  width: 100px;
}
.w-\[120px\] {
  width: 120px;
}
.w-\[16px\] {
  width: 16px;
}
.w-\[180px\] {
  width: 180px;
}
.w-\[18px\] {
  width: 18px;
}
.w-\[190px\] {
  width: 190px;
}
.w-\[220px\] {
  width: 220px;
}
.w-\[260px\] {
  width: 260px;
}
.w-\[300px\] {
  width: 300px;
}
.w-\[320px\] {
  width: 320px;
}
.w-\[360px\] {
  width: 360px;
}
.w-\[40px\] {
  width: 40px;
}
.w-\[42px\] {
  width: 42px;
}
.w-\[480px\] {
  width: 480px;
}
.w-\[60\] {
  width: 60;
}
.w-\[60px\] {
  width: 60px;
}
.w-\[80\%\] {
  width: 80%;
}
.w-\[80px\] {
  width: 80px;
}
.w-\[95px\] {
  width: 95px;
}
.w-\[calc\(100\%\+32px\)\] {
  width: calc(100% + 32px);
}
.w-\[calc\(100vw_-_680px\)\] {
  width: calc(100vw - 680px);
}
.w-auto {
  width: auto;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.w-full {
  width: 100%;
}
.min-w-\[100px\] {
  min-width: 100px;
}
.min-w-\[120px\] {
  min-width: 120px;
}
.min-w-\[210px\] {
  min-width: 210px;
}
.min-w-\[240px\] {
  min-width: 240px;
}
.min-w-\[260px\] {
  min-width: 260px;
}
.min-w-\[300px\] {
  min-width: 300px;
}
.min-w-\[340px\] {
  min-width: 340px;
}
.min-w-\[400px\] {
  min-width: 400px;
}
.min-w-\[54px\] {
  min-width: 54px;
}
.min-w-max {
  min-width: -moz-max-content;
  min-width: max-content;
}
.min-w-min {
  min-width: -moz-min-content;
  min-width: min-content;
}
.\!max-w-\[1080px\] {
  max-width: 1080px !important;
}
.\!max-w-none {
  max-width: none !important;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-\[100vw\] {
  max-width: 100vw;
}
.max-w-\[1220px\] {
  max-width: 1220px;
}
.max-w-\[1280px\] {
  max-width: 1280px;
}
.max-w-\[128px\] {
  max-width: 128px;
}
.max-w-\[1320px\] {
  max-width: 1320px;
}
.max-w-\[1360px\] {
  max-width: 1360px;
}
.max-w-\[1440px\] {
  max-width: 1440px;
}
.max-w-\[180px\] {
  max-width: 180px;
}
.max-w-\[230px\] {
  max-width: 230px;
}
.max-w-\[260px\] {
  max-width: 260px;
}
.max-w-\[460px\] {
  max-width: 460px;
}
.max-w-\[480px\] {
  max-width: 480px;
}
.max-w-\[50rem\] {
  max-width: 50rem;
}
.max-w-\[523px\] {
  max-width: 523px;
}
.max-w-\[630px\] {
  max-width: 630px;
}
.max-w-\[640px\] {
  max-width: 640px;
}
.max-w-\[660px\] {
  max-width: 660px;
}
.max-w-\[670px\] {
  max-width: 670px;
}
.max-w-\[800px\] {
  max-width: 800px;
}
.max-w-\[820px\] {
  max-width: 820px;
}
.max-w-\[860px\] {
  max-width: 860px;
}
.max-w-\[900px\] {
  max-width: 900px;
}
.max-w-\[95px\] {
  max-width: 95px;
}
.max-w-full {
  max-width: 100%;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-none {
  max-width: none;
}
.max-w-screen-sm {
  max-width: 640px;
}
.max-w-xl {
  max-width: 36rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-grow {
  flex-grow: 1;
}
.grow {
  flex-grow: 1;
}
.border-collapse {
  border-collapse: collapse;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-x-10 {
  --tw-translate-x: -2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-5 {
  --tw-translate-y: -1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-10 {
  --tw-translate-x: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-5 {
  --tw-translate-y: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.resize {
  resize: both;
}
.scroll-pt-6 {
  scroll-padding-top: 1.5rem;
}
.list-\[revert\] {
  list-style-type: revert;
}
.auto-rows-min {
  grid-auto-rows: min-content;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-\[16px\2c _1fr\] {
  grid-template-columns: 16px 1fr;
}
.grid-cols-\[1fr\2c _0px\] {
  grid-template-columns: 1fr 0px;
}
.grid-cols-\[1fr\2c _2fr\] {
  grid-template-columns: 1fr 2fr;
}
.grid-cols-\[24px\2c _1fr\] {
  grid-template-columns: 24px 1fr;
}
.grid-cols-\[300px\2c _1fr\] {
  grid-template-columns: 300px 1fr;
}
.grid-cols-\[max-content_1fr\] {
  grid-template-columns: max-content 1fr;
}
.grid-cols-\[minmax\(min-content\2c _max-content\)_minmax\(max-content\2c _6rem\)_minmax\(max-content\2c _6rem\)\] {
  grid-template-columns: minmax(min-content, max-content) minmax(max-content, 6rem) minmax(max-content, 6rem);
}
.grid-cols-\[repeat\(auto-fill\2c _minmax\(264px\2c _1fr\)\)\] {
  grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
}
.grid-cols-\[repeat\(auto-fit\2c _269px\)\] {
  grid-template-columns: repeat(auto-fit, 269px);
}
.grid-cols-\[repeat\(auto-fit\2c _minmax\(264px\2c _1fr\)\)\] {
  grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
}
.grid-cols-\[repeat\(auto-fit\2c _minmax\(269px\2c _1fr\)\)\] {
  grid-template-columns: repeat(auto-fit, minmax(269px, 1fr));
}
.grid-cols-\[repeat\(auto-fit\2c _minmax\(270px\2c _1fr\)\)\] {
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
}
.grid-cols-\[repeat\(auto-fit\2c _minmax\(306px\2c _1fr\)\)\] {
  grid-template-columns: repeat(auto-fit, minmax(306px, 1fr));
}
.grid-cols-\[repeat\(auto-fit\2c _minmax\(320px\2c _1fr\)\)\] {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}
.grid-rows-\[48px\2c _0fr\] {
  grid-template-rows: 48px 0fr;
}
.grid-rows-\[48px\2c _1fr\] {
  grid-template-rows: 48px 1fr;
}
.grid-rows-\[min-content\2c _0fr\] {
  grid-template-rows: min-content 0fr;
}
.grid-rows-\[min-content\2c _1fr\] {
  grid-template-rows: min-content 1fr;
}
.grid-rows-\[repeat\(auto-fit\2c _200px\)\] {
  grid-template-rows: repeat(auto-fit, 200px);
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.content-center {
  align-content: center;
}
.content-start {
  align-content: flex-start;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}
.justify-normal {
  justify-content: normal;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-stretch {
  justify-content: stretch;
}
.justify-items-start {
  justify-items: start;
}
.justify-items-center {
  justify-items: center;
}
.gap-0 {
  gap: 0px;
}
.gap-0\.5 {
  gap: 0.125rem;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-1\.5 {
  gap: 0.375rem;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-12 {
  gap: 3rem;
}
.gap-15 {
  gap: 3.75rem;
}
.gap-16 {
  gap: 4rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-2\.5 {
  gap: 0.625rem;
}
.gap-24 {
  gap: 6rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-3\.5 {
  gap: 0.875rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-7 {
  gap: 1.75rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-9 {
  gap: 2.25rem;
}
.gap-\[112px\] {
  gap: 112px;
}
.gap-\[128px\] {
  gap: 128px;
}
.gap-\[160px\] {
  gap: 160px;
}
.gap-\[30px\] {
  gap: 30px;
}
.gap-\[50px\] {
  gap: 50px;
}
.gap-\[60px\] {
  gap: 60px;
}
.gap-\[74px\] {
  gap: 74px;
}
.gap-\[80px\] {
  gap: 80px;
}
.gap-\[88px\] {
  gap: 88px;
}
.gap-x-1 {
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}
.gap-x-1\.5 {
  -moz-column-gap: 0.375rem;
       column-gap: 0.375rem;
}
.gap-x-15 {
  -moz-column-gap: 3.75rem;
       column-gap: 3.75rem;
}
.gap-x-5 {
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
}
.gap-x-6 {
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}
.gap-y-1 {
  row-gap: 0.25rem;
}
.gap-y-4 {
  row-gap: 1rem;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.place-self-center {
  place-self: center;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.justify-self-start {
  justify-self: start;
}
.justify-self-end {
  justify-self: end;
}
.justify-self-center {
  justify-self: center;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-break-spaces {
  white-space: break-spaces;
}
.text-nowrap {
  text-wrap: nowrap;
}
.break-words {
  overflow-wrap: break-word;
}
.\!rounded-\[50px\] {
  border-radius: 50px !important;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-\[100px\] {
  border-radius: 100px;
}
.rounded-\[120px\] {
  border-radius: 120px;
}
.rounded-\[12px\] {
  border-radius: 12px;
}
.rounded-\[16px\] {
  border-radius: 16px;
}
.rounded-\[20px\] {
  border-radius: 20px;
}
.rounded-\[30px\] {
  border-radius: 30px;
}
.rounded-\[40px\] {
  border-radius: 40px;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-none {
  border-radius: 0px;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-l-\[30px\] {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.rounded-s-\[30px\] {
  border-start-start-radius: 30px;
  border-end-start-radius: 30px;
}
.rounded-t-\[12px\] {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.rounded-br-\[80px\] {
  border-bottom-right-radius: 80px;
}
.rounded-tl-\[80px\] {
  border-top-left-radius: 80px;
}
.rounded-tl-none {
  border-top-left-radius: 0px;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-2 {
  border-width: 2px;
}
.border-\[10px\] {
  border-width: 10px;
}
.border-b-4 {
  border-bottom-width: 4px;
}
.border-b-\[0\.5px\] {
  border-bottom-width: 0.5px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-r-4 {
  border-right-width: 4px;
}
.border-t-0 {
  border-top-width: 0px;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-hidden {
  border-style: hidden;
}
.border-none {
  border-style: none;
}
.border-\[\#B8E2F5\] {
  --tw-border-opacity: 1;
  border-color: rgb(184 226 245 / var(--tw-border-opacity, 1));
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(17 22 23 / var(--tw-border-opacity, 1));
}
.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(184 226 245 / var(--tw-border-opacity, 1));
}
.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(0 153 218 / var(--tw-border-opacity, 1));
}
.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(2 117 179 / var(--tw-border-opacity, 1));
}
.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(52 110 88 / var(--tw-border-opacity, 1));
}
.border-grey {
  --tw-border-opacity: 1;
  border-color: rgb(117 142 153 / var(--tw-border-opacity, 1));
}
.border-light-grey {
  --tw-border-opacity: 1;
  border-color: rgb(211 219 222 / var(--tw-border-opacity, 1));
}
.border-milestone-blue {
  --tw-border-opacity: 1;
  border-color: rgb(0 153 218 / var(--tw-border-opacity, 1));
}
.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(227 228 228 / var(--tw-border-opacity, 1));
}
.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(206 208 208 / var(--tw-border-opacity, 1));
}
.border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(136 138 139 / var(--tw-border-opacity, 1));
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.\!bg-green-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(237 247 243 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-grey {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(117 142 153 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(17 22 23 / var(--tw-bg-opacity, 1));
}
.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(184 226 245 / var(--tw-bg-opacity, 1));
}
.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(230 245 252 / var(--tw-bg-opacity, 1));
}
.bg-blue-600-opacity-005 {
  background-color: #0099DA0D;
}
.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 117 179 / var(--tw-bg-opacity, 1));
}
.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 85 145 / var(--tw-bg-opacity, 1));
}
.bg-deep-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(0 82 117 / var(--tw-bg-opacity, 1));
}
.bg-deep-dust {
  --tw-bg-opacity: 1;
  background-color: rgb(240 243 244 / var(--tw-bg-opacity, 1));
}
.bg-dust {
  --tw-bg-opacity: 1;
  background-color: rgb(246 248 248 / var(--tw-bg-opacity, 1));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}
.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 240 231 / var(--tw-bg-opacity, 1));
}
.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 228 213 / var(--tw-bg-opacity, 1));
}
.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 247 243 / var(--tw-bg-opacity, 1));
}
.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 179 136 / var(--tw-bg-opacity, 1));
}
.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 110 88 / var(--tw-bg-opacity, 1));
}
.bg-light-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 245 254 / var(--tw-bg-opacity, 1));
}
.bg-light-green {
  --tw-bg-opacity: 1;
  background-color: rgb(244 251 242 / var(--tw-bg-opacity, 1));
}
.bg-neutral-10 {
  background-color: #0000000a;
}
.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 242 242 / var(--tw-bg-opacity, 1));
}
.bg-neutral-100-opacity-005 {
  background-color: #4244450D;
}
.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 228 228 / var(--tw-bg-opacity, 1));
}
.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 248 248 / var(--tw-bg-opacity, 1));
}
.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 222 192 / var(--tw-bg-opacity, 1));
}
.bg-orange-1000 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 75 50 / var(--tw-bg-opacity, 1));
}
.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 243 230 / var(--tw-bg-opacity, 1));
}
.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(201 97 69 / var(--tw-bg-opacity, 1));
}
.bg-primary-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 231 0 / var(--tw-bg-opacity, 1));
}
.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 211 210 / var(--tw-bg-opacity, 1));
}
.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 233 233 / var(--tw-bg-opacity, 1));
}
.bg-tranparent-grey-5 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 233 233 / var(--tw-bg-opacity, 1));
}
.bg-transparent {
  background-color: transparent;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-opacity-\[0\.04\] {
  --tw-bg-opacity: 0.04;
}
.\!bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
}
.bg-\[linear-gradient\(91deg\2c var\(--tw-gradient-stops\)\)\] {
  background-image: linear-gradient(91deg,var(--tw-gradient-stops));
}
.from-blue-900 {
  --tw-gradient-from: #005591 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 85 145 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-orange-50\/15 {
  --tw-gradient-from: rgb(253 243 230 / 0.15) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(253 243 230 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-blue-600 {
  --tw-gradient-to: #0099DA var(--tw-gradient-to-position);
}
.to-orange-200\/15 {
  --tw-gradient-to: rgb(248 202 155 / 0.15) var(--tw-gradient-to-position);
}
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
.fill-alert-error {
  fill: #DA3D00;
}
.fill-blue-900 {
  fill: #005591;
}
.fill-green-700 {
  fill: #3F8D6E;
}
.fill-neutral-600 {
  fill: #58595B;
}
.fill-neutral-700 {
  fill: #424445;
}
.fill-red-700 {
  fill: #C00400;
}
.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}
.object-center {
  -o-object-position: center;
     object-position: center;
}
.\!p-0 {
  padding: 0px !important;
}
.p-0 {
  padding: 0px;
}
.p-1 {
  padding: 0.25rem;
}
.p-1\.5 {
  padding: 0.375rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-15 {
  padding: 3.75rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-2\.5 {
  padding: 0.625rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-3\.5 {
  padding: 0.875rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-7 {
  padding: 1.75rem;
}
.p-8 {
  padding: 2rem;
}
.p-\[30px\] {
  padding: 30px;
}
.p-\[revert\] {
  padding: revert;
}
.\!px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.\!px-\[30px\] {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.\!py-\[60px\] {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-\[10\%\] {
  padding-left: 10%;
  padding-right: 10%;
}
.px-\[60px\] {
  padding-left: 60px;
  padding-right: 60px;
}
.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}
.py-\[30px\] {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}
.\!pb-0 {
  padding-bottom: 0px !important;
}
.\!pt-0 {
  padding-top: 0px !important;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-14 {
  padding-bottom: 3.5rem;
}
.pb-16 {
  padding-bottom: 4rem;
}
.pb-2\.5 {
  padding-bottom: 0.625rem;
}
.pb-32 {
  padding-bottom: 8rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-52 {
  padding-bottom: 13rem;
}
.pb-\[160px\] {
  padding-bottom: 160px;
}
.pb-\[30px\] {
  padding-bottom: 30px;
}
.pl-0 {
  padding-left: 0px;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-2\.5 {
  padding-left: 0.625rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-7 {
  padding-left: 1.75rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2\.5 {
  padding-right: 0.625rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-24 {
  padding-top: 6rem;
}
.pt-28 {
  padding-top: 7rem;
}
.pt-3\.5 {
  padding-top: 0.875rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pt-\[30px\] {
  padding-top: 30px;
}
.pt-\[52px\] {
  padding-top: 52px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-start {
  text-align: start;
}
.align-middle {
  vertical-align: middle;
}
.text-\[10px\] {
  font-size: 10px;
}
.text-\[12px\] {
  font-size: 12px;
}
.text-\[13px\] {
  font-size: 13px;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-\[19px\] {
  font-size: 19px;
}
.text-\[20px\] {
  font-size: 20px;
}
.text-\[48px\] {
  font-size: 48px;
}
.text-base {
  font-size: 16px;
}
.text-h1 {
  font-size: 64px;
}
.text-h2 {
  font-size: 48px;
}
.text-h3 {
  font-size: 36px;
}
.text-h4 {
  font-size: 28px;
}
.text-h5 {
  font-size: 21px;
}
.text-h6 {
  font-size: 18px;
}
.text-md {
  font-size: 14px;
}
.text-micro {
  font-size: 10px;
}
.text-p1 {
  font-size: 16px;
}
.text-p2 {
  font-size: 14px;
}
.text-p3 {
  font-size: 24px;
}
.text-sm {
  font-size: 13px;
}
.\!font-bold {
  font-weight: 700 !important;
}
.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 300;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.italic {
  font-style: italic;
}
.leading-\[20px\] {
  line-height: 20px;
}
.leading-\[52px\] {
  line-height: 52px;
}
.leading-base {
  line-height: 22px;
}
.leading-h1 {
  line-height: 74px;
}
.leading-h2 {
  line-height: 60px;
}
.leading-h3 {
  line-height: 44px;
}
.leading-h4 {
  line-height: 38px;
}
.leading-h5 {
  line-height: 28px;
}
.leading-h6 {
  line-height: 24px;
}
.leading-md {
  line-height: 18px;
}
.\!text-blue-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(0 153 218 / var(--tw-text-opacity, 1)) !important;
}
.\!text-blue-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(0 134 198 / var(--tw-text-opacity, 1)) !important;
}
.\!text-blue-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(2 117 179 / var(--tw-text-opacity, 1)) !important;
}
.\!text-light-grey {
  --tw-text-opacity: 1 !important;
  color: rgb(211 219 222 / var(--tw-text-opacity, 1)) !important;
}
.\!text-milestone-blue {
  --tw-text-opacity: 1 !important;
  color: rgb(0 153 218 / var(--tw-text-opacity, 1)) !important;
}
.\!text-neutral-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(88 89 91 / var(--tw-text-opacity, 1)) !important;
}
.text-alert-confirmation {
  --tw-text-opacity: 1;
  color: rgb(42 173 96 / var(--tw-text-opacity, 1));
}
.text-alert-error {
  --tw-text-opacity: 1;
  color: rgb(218 61 0 / var(--tw-text-opacity, 1));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(17 22 23 / var(--tw-text-opacity, 1));
}
.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(153 214 240 / var(--tw-text-opacity, 1));
}
.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(102 194 233 / var(--tw-text-opacity, 1));
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(0 153 218 / var(--tw-text-opacity, 1));
}
.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(0 134 198 / var(--tw-text-opacity, 1));
}
.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(2 117 179 / var(--tw-text-opacity, 1));
}
.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(0 85 145 / var(--tw-text-opacity, 1));
}
.text-dark-grey {
  --tw-text-opacity: 1;
  color: rgb(72 89 97 / var(--tw-text-opacity, 1));
}
.text-deep-blue {
  --tw-text-opacity: 1;
  color: rgb(0 82 117 / var(--tw-text-opacity, 1));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
.text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(192 228 213 / var(--tw-text-opacity, 1));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(94 187 148 / var(--tw-text-opacity, 1));
}
.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(76 179 136 / var(--tw-text-opacity, 1));
}
.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(63 141 110 / var(--tw-text-opacity, 1));
}
.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(46 93 76 / var(--tw-text-opacity, 1));
}
.text-grey {
  --tw-text-opacity: 1;
  color: rgb(117 142 153 / var(--tw-text-opacity, 1));
}
.text-inherit {
  color: inherit;
}
.text-light-grey {
  --tw-text-opacity: 1;
  color: rgb(211 219 222 / var(--tw-text-opacity, 1));
}
.text-milestone-blue {
  --tw-text-opacity: 1;
  color: rgb(0 153 218 / var(--tw-text-opacity, 1));
}
.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(136 138 139 / var(--tw-text-opacity, 1));
}
.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(88 89 91 / var(--tw-text-opacity, 1));
}
.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(66 68 69 / var(--tw-text-opacity, 1));
}
.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(45 47 48 / var(--tw-text-opacity, 1));
}
.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(22 26 30 / var(--tw-text-opacity, 1));
}
.text-orange-1000 {
  --tw-text-opacity: 1;
  color: rgb(168 75 50 / var(--tw-text-opacity, 1));
}
.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(201 97 69 / var(--tw-text-opacity, 1));
}
.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(192 4 0 / var(--tw-text-opacity, 1));
}
.text-red-error {
  --tw-text-opacity: 1;
  color: rgb(211 47 47 / var(--tw-text-opacity, 1));
}
.text-review-orange {
  --tw-text-opacity: 1;
  color: rgb(255 149 92 / var(--tw-text-opacity, 1));
}
.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(88 89 91 / var(--tw-text-opacity, 1));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.underline {
  text-decoration-line: underline;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-80 {
  opacity: 0.8;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_-2px_8px_0px_rgb\(0_0_0_\/_0\.1\)\] {
  --tw-shadow: 0 -2px 8px 0px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 -2px 8px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_4px_10px_0_rgba\(0\2c 0\2c 0\2c 0\.10\)\] {
  --tw-shadow: 0 4px 10px 0 rgba(0,0,0,0.10);
  --tw-shadow-colored: 0 4px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_4px_10px_0_rgba\(72\2c 89\2c 97\2c 0\.10\)\] {
  --tw-shadow: 0 4px 10px 0 rgba(72,89,97,0.10);
  --tw-shadow-colored: 0 4px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[8px_0_10px_0_rgba\(72\2c 89\2c 97\2c 0\.10\)\] {
  --tw-shadow: 8px 0 10px 0 rgba(72,89,97,0.10);
  --tw-shadow-colored: 8px 0 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.\!outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}
.outline {
  outline-style: solid;
}
.outline-1 {
  outline-width: 1px;
}
.outline-blue-900 {
  outline-color: #005591;
}
.outline-green-600 {
  outline-color: #4CB388;
}
.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[200px\] {
  --tw-blur: blur(200px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale-\[1\] {
  --tw-grayscale: grayscale(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-invert {
  --tw-backdrop-invert: invert(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[box-shadow\] {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[grid-template-columns\] {
  transition-property: grid-template-columns;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[grid-template-rows\] {
  transition-property: grid-template-rows;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[max-width\] {
  transition-property: max-width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[transform\] {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[width\] {
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-200 {
  transition-duration: 200ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.duration-500 {
  transition-duration: 500ms;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-linear {
  transition-timing-function: linear;
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.main-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1360px;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 640px) {
  .main-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.anchor {
  font-weight: 600;
  text-decoration-line: underline;
}
.anchor:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(0 153 218 / var(--tw-text-opacity, 1)) !important;
}
/* a.NavLink-link {
    color: theme('colors.black');
  } */

/* CSS RESET */
img {
  height: auto;
}
a {
  text-decoration: none;
}
a:not(.MuiButton-root):not(.Milestone-link) {
  color: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* END CSS RESET */

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --text-primary: #161A1E;
}

body,
main {
  /* color: rgb(var(--foreground-rgb)); */
  color: var(--text-primary);
  background: #FFFFFF;
}

.disabled:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(211 219 222 / var(--tw-text-opacity, 1)) !important;
}

.markdown p:not(:first-child) {
  margin-top: 1.25rem !important;
}

.markdown-legal h2 {
  margin-top: 20px !important;
}

.cms-inline-link {
  display: inline;
  p {
    display: inline;
  }

  &:before {
    content: ' ';
    display: inline-block;
    width: 0.25rem;
  }
}

.cms-inline-markdown {
  display: inline;
  * {
    display: inline;
  }
}

.last-of-type\:border-0:last-of-type {
  border-width: 0px;
}

.visited\:text-inherit:visited {
  color: inherit;
}

.hover\:\!rounded-full:hover {
  border-radius: 9999px !important;
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 153 218 / var(--tw-border-opacity, 1));
}

.hover\:\!bg-green-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(237 247 243 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-orange-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(253 243 230 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:bg-black_states-hover:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(230 245 252 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 247 243 / var(--tw-bg-opacity, 1));
}

.hover\:bg-grey:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(117 142 153 / var(--tw-bg-opacity, 1));
}

.hover\:bg-neutral-10:hover {
  background-color: #0000000a;
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 242 242 / var(--tw-bg-opacity, 1));
}

.hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(227 228 228 / var(--tw-bg-opacity, 1));
}

.hover\:\!text-black:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(17 22 23 / var(--tw-text-opacity, 1)) !important;
}

.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgb(153 214 240 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgb(2 117 179 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgb(0 85 145 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.hover\:text-milestone-blue:hover {
  --tw-text-opacity: 1;
  color: rgb(0 153 218 / var(--tw-text-opacity, 1));
}

.hover\:text-neutral-400:hover {
  --tw-text-opacity: 1;
  color: rgb(186 189 190 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-drop-shadow-blue:hover {
  --tw-shadow: 0px 153px 43px 0px rgba(0, 153, 218, 0.00), 0px 98px 39px 0px rgba(0, 153, 218, 0.01), 0px 55px 33px 0px rgba(0, 153, 218, 0.05), 0px 24px 24px 0px rgba(0, 153, 218, 0.09), 0px 6px 13px 0px rgba(0, 153, 218, 0.10);
  --tw-shadow-colored: 0px 153px 43px 0px var(--tw-shadow-color), 0px 98px 39px 0px var(--tw-shadow-color), 0px 55px 33px 0px var(--tw-shadow-color), 0px 24px 24px 0px var(--tw-shadow-color), 0px 6px 13px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-drop-shadow-green:hover {
  --tw-shadow: 0px 122px 34px 0px rgba(63, 141, 110, 0.00), 0px 78px 31px 0px rgba(63, 141, 110, 0.01), 0px 44px 26px 0px rgba(63, 141, 110, 0.05), 0px 20px 20px 0px rgba(63, 141, 110, 0.09), 0px 5px 11px 0px rgba(63, 141, 110, 0.10);
  --tw-shadow-colored: 0px 122px 34px 0px var(--tw-shadow-color), 0px 78px 31px 0px var(--tw-shadow-color), 0px 44px 26px 0px var(--tw-shadow-color), 0px 20px 20px 0px var(--tw-shadow-color), 0px 5px 11px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-drop-shadow-orange:hover {
  --tw-shadow: 0px 214px 60px 0px rgba(231, 143, 87, 0.00), 0px 137px 55px 0px rgba(231, 143, 87, 0.01), 0px 77px 46px 0px rgba(231, 143, 87, 0.05), 0px 34px 34px 0px rgba(231, 143, 87, 0.09), 0px 9px 19px 0px rgba(231, 143, 87, 0.10);
  --tw-shadow-colored: 0px 214px 60px 0px var(--tw-shadow-color), 0px 137px 55px 0px var(--tw-shadow-color), 0px 77px 46px 0px var(--tw-shadow-color), 0px 34px 34px 0px var(--tw-shadow-color), 0px 9px 19px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-\[--shadow-value\]:hover {
  --tw-shadow-color: var(--shadow-value);
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:\!drop-shadow-lg:hover {
  --tw-drop-shadow: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1)) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.hover\:drop-shadow-xl:hover {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media not all and (min-width: 1024px) {
  .max-lg\:row-start-2 {
    grid-row-start: 2;
  }
}

@media (min-width: 640px) {
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:ml-auto {
    margin-left: auto;
  }
  .sm\:h-\[450px\] {
    height: 450px;
  }
  .sm\:max-h-\[340px\] {
    max-height: 340px;
  }
  .sm\:w-\[calc\(100\%\+80px\)\] {
    width: calc(100% + 80px);
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:gap-10 {
    gap: 2.5rem;
  }
  .sm\:gap-15 {
    gap: 3.75rem;
  }
  .sm\:gap-4 {
    gap: 1rem;
  }
  .sm\:gap-5 {
    gap: 1.25rem;
  }
  .sm\:bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 242 242 / var(--tw-bg-opacity, 1));
  }
  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }
  .sm\:p-0 {
    padding: 0px;
  }
  .sm\:p-10 {
    padding: 2.5rem;
  }
  .sm\:p-15 {
    padding: 3.75rem;
  }
  .sm\:p-5 {
    padding: 1.25rem;
  }
  .sm\:p-8 {
    padding: 2rem;
  }
  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:py-\[140px\] {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .sm\:text-center {
    text-align: center;
  }
  .sm\:text-h1 {
    font-size: 64px;
  }
  .sm\:text-h2 {
    font-size: 48px;
  }
  .sm\:text-h3 {
    font-size: 36px;
  }
  .sm\:text-h4 {
    font-size: 28px;
  }
  .sm\:text-h5 {
    font-size: 21px;
  }
  .sm\:leading-h1 {
    line-height: 74px;
  }
  .sm\:leading-h2 {
    line-height: 60px;
  }
  .sm\:leading-h3 {
    line-height: 44px;
  }
  .sm\:leading-h4 {
    line-height: 38px;
  }
  .sm\:leading-h5 {
    line-height: 28px;
  }
  @media not all and (min-width: 1280px) {
    .sm\:max-xl\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @media not all and (min-width: 1024px) {
    .sm\:max-lg\:max-h-none {
      max-height: none;
    }
    .sm\:max-lg\:flex-1 {
      flex: 1 1 0%;
    }
    .sm\:max-lg\:basis-full {
      flex-basis: 100%;
    }
    .sm\:max-lg\:flex-row {
      flex-direction: row;
    }
  }
}

@media (min-width: 768px) {
  .md\:bottom-0 {
    bottom: 0px;
  }
  .md\:left-0 {
    left: 0px;
  }
  .md\:right-0 {
    right: 0px;
  }
  .md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:row-start-1 {
    grid-row-start: 1;
  }
  .md\:-mt-60 {
    margin-top: -15rem;
  }
  .md\:h-\[500px\] {
    height: 500px;
  }
  .md\:max-h-\[400px\] {
    max-height: 400px;
  }
  .md\:w-1\/2 {
    width: 50%;
  }
  .md\:w-\[660px\] {
    width: 660px;
  }
  .md\:w-\[min\(680px\2c _50\%\)\] {
    width: min(680px, 50%);
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:min-w-\[300px\] {
    min-width: 300px;
  }
  .md\:max-w-4xl {
    max-width: 56rem;
  }
  .md\:max-w-\[430px\] {
    max-width: 430px;
  }
  .md\:max-w-\[540px\] {
    max-width: 540px;
  }
  .md\:max-w-full {
    max-width: 100%;
  }
  .md\:max-w-md {
    max-width: 28rem;
  }
  .md\:max-w-none {
    max-width: none;
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .md\:content-center {
    align-content: center;
  }
  .md\:content-start {
    align-content: flex-start;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:justify-end {
    justify-content: flex-end;
  }
  .md\:gap-1 {
    gap: 0.25rem;
  }
  .md\:gap-10 {
    gap: 2.5rem;
  }
  .md\:gap-12 {
    gap: 3rem;
  }
  .md\:gap-2 {
    gap: 0.5rem;
  }
  .md\:gap-\[54px\] {
    gap: 54px;
  }
  .md\:p-10 {
    padding: 2.5rem;
  }
  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:pb-32 {
    padding-bottom: 8rem;
  }
  .md\:pt-32 {
    padding-top: 8rem;
  }
  .md\:text-left {
    text-align: left;
  }
  .md\:text-center {
    text-align: center;
  }
  .md\:text-start {
    text-align: start;
  }
  .md\:text-\[48px\] {
    font-size: 48px;
  }
  .md\:text-h2 {
    font-size: 48px;
  }
  .md\:text-h3 {
    font-size: 36px;
  }
  .md\:text-h5 {
    font-size: 21px;
  }
  .md\:leading-\[48px\] {
    line-height: 48px;
  }
  .md\:leading-h2 {
    line-height: 60px;
  }
  .md\:leading-h3 {
    line-height: 44px;
  }
  .md\:leading-h5 {
    line-height: 28px;
  }
}

@media (min-width: 1024px) {
  .lg\:sticky {
    position: sticky;
  }
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg\:-mt-72 {
    margin-top: -18rem;
  }
  .lg\:flex {
    display: flex;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:h-\[160px\] {
    height: 160px;
  }
  .lg\:h-\[574px\] {
    height: 574px;
  }
  .lg\:h-full {
    height: 100%;
  }
  .lg\:max-h-none {
    max-height: none;
  }
  .lg\:min-h-\[32px\] {
    min-height: 32px;
  }
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
  .lg\:w-\[200px\] {
    width: 200px;
  }
  .lg\:w-full {
    width: 100%;
  }
  .lg\:max-w-\[400px\] {
    max-width: 400px;
  }
  .lg\:max-w-\[50\%\] {
    max-width: 50%;
  }
  .lg\:max-w-\[540px\] {
    max-width: 540px;
  }
  .lg\:max-w-sm {
    max-width: 24rem;
  }
  .lg\:flex-1 {
    flex: 1 1 0%;
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-\[max-content\2c _1fr\] {
    grid-template-columns: max-content 1fr;
  }
  .lg\:grid-cols-\[minmax\(auto\2c _150px\)\2c 1fr\2c minmax\(auto\2c _270px\)\] {
    grid-template-columns: minmax(auto, 150px) 1fr minmax(auto, 270px);
  }
  .lg\:grid-cols-\[minmax\(min-content\2c 680px\)\2c _1fr\] {
    grid-template-columns: minmax(min-content,680px) 1fr;
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .lg\:flex-col {
    flex-direction: column;
  }
  .lg\:items-center {
    align-items: center;
  }
  .lg\:justify-between {
    justify-content: space-between;
  }
  .lg\:gap-4 {
    gap: 1rem;
  }
  .lg\:gap-\[72px\] {
    gap: 72px;
  }
  .lg\:gap-\[80px\] {
    gap: 80px;
  }
  .lg\:p-15 {
    padding: 3.75rem;
  }
  .lg\:p-20 {
    padding: 5rem;
  }
  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .lg\:py-\[140px\] {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .lg\:text-center {
    text-align: center;
  }
  .lg\:text-\[96px\] {
    font-size: 96px;
  }
  .lg\:text-h1 {
    font-size: 64px;
  }
  .lg\:text-h4 {
    font-size: 28px;
  }
  .lg\:leading-\[100px\] {
    line-height: 100px;
  }
  .lg\:leading-h1 {
    line-height: 74px;
  }
  .lg\:leading-h4 {
    line-height: 38px;
  }
  @media not all and (min-width: 1280px) {
    .lg\:max-xl\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media (min-width: 1280px) {
  .xl\:-right-5 {
    right: -1.25rem;
  }
  .xl\:-top-12 {
    top: -3rem;
  }
  .xl\:right-5 {
    right: 1.25rem;
  }
  .xl\:top-0 {
    top: 0px;
  }
  .xl\:min-h-\[300px\] {
    min-height: 300px;
  }
  .xl\:w-1\/2 {
    width: 50%;
  }
  .xl\:w-\[20\%\] {
    width: 20%;
  }
  .xl\:w-\[25\%\] {
    width: 25%;
  }
  .xl\:w-\[37\.5\%\] {
    width: 37.5%;
  }
  .xl\:w-\[40\%\] {
    width: 40%;
  }
  .xl\:flex-1 {
    flex: 1 1 0%;
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .xl\:text-base {
    font-size: 16px;
  }
  .xl\:text-h3 {
    font-size: 36px;
  }
  .xl\:leading-h3 {
    line-height: 44px;
  }
}

@media (min-width: 2800px) {
  .xxxxl\:h-\[1200px\] {
    height: 1200px;
  }
}

